import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import SmallButton from "../components/Button/SmallButton";
import OrangeButton from "../components/Button/OrangeButton";
import { Carousel } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { productDetailData as data } from "../data/productDetail";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import "../scss/productdetail.scss";
import Gluten from "../img/icons/gluten.webp";
import Freeze from "../img/icons/freeze.webp";
import { Link } from "gatsby";
const ProductDetailBanner = styled.div`
  width: 100%;
  height: 55vh;
  @media screen and (max-width: 1600px) {
    height: 70vh;
  }
  @media screen and (max-width: 900px) {
    height: 30vh;
  }
  @media screen and (max-width: 630px) {
    height: 25vh;
  }
`;
const ProductDetail = (props) => {
  const { pageContext } = props;
  const i = pageContext?.id ?? 0;
  const props_ = { scale: 1.5, zoomPosition: "original" };
  return (
    <Layout page={`productDetail`} title={data[i].metatitle} description={data[i].metadescription}>
      <div className="productDetail">
        <ProductDetailBanner className="productDetailBanner">
          <img loading="lazy" src={data[i].cover} alt={data[i].bannerAlt ? data[i].bannerAlt : "ll"} className="bannerImg" />
        </ProductDetailBanner>
        <div className="productDetailContent">
          <div className="backBtn">
            <SmallButton msg="back to products" icon="backward" to="/products" color="white" />
          </div>
          <div className="productDetails">
            <div className="productCarousel">
              <div>
                <Carousel className="productarouselWrapper" interval={null}>
                  <Carousel.Item className="productCarouselItem">
                    <img loading="lazy" src={data[i].car1} alt={data[i].carAlt1 ?? "hero1"} className="w-75 d-block pc-img" />
                  </Carousel.Item>
                  <Carousel.Item className="productCarouselItem">
                    <InnerImageZoom src={data[i].car2} className="w-75 d-block pc-img" zoomScale={0.5} zoomType="hover" imgAttributes={{ alt: data[i].carAlt2 ?? "hero2" }} />
                  </Carousel.Item>
                  <Carousel.Item className="productCarouselItem">
                    <img loading="lazy" src={data[i].car3} alt={data[i].carAlt3 ?? "hero1"} className="w-75 d-block pc-img" />
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
            <div className="productDesc">
              <h3>{data[i].subtitle}</h3>
              <h1 dangerouslySetInnerHTML={{ __html: data[i].title }}></h1>
              <h3>{data[i].weight}</h3>
              <br />
              <small>{"*per 100 g serving"}</small>
              <div className="productDetailServingInfo">
                <div className="productDetailIcons">
                  <div className="productDetailIcon">
                    <StaticImage loading="lazy" src="../img/icons/protein.webp" alt="protein" />
                  </div>
                  <div className="productDetailIconContent">
                    <h3>{data[i].protein}</h3>
                    <p>protein</p>
                  </div>
                </div>
                <div className="productDetailIcons">
                  <div className="productDetailIcon">
                    <StaticImage loading="lazy" src="../img/icons/fibre.webp" alt="protein" />
                  </div>
                  <div className="productDetailIconContent">
                    <h3>{data[i].fibre}</h3>
                    <p>fiber</p>
                  </div>
                </div>
                <div className="productDetailIcons">
                  <div className="productDetailIcon">
                    <StaticImage loading="lazy" src="../img/icons/cholestrol.webp" alt="protein" />
                  </div>
                  <div className="productDetailIconContent">
                    <h3>0%</h3>
                    <p>trans fat</p>
                  </div>
                </div>
                <div className="productDetailIcons productDetailplants">
                  <div className="productDetailIcon">
                    <StaticImage loading="lazy" src="../img/icons/plants.webp" alt="protein" />
                  </div>
                  <div className="productDetailIconContent ">
                    <h3>Ingredients</h3>
                    <p>made from plants</p>
                  </div>
                </div>
              </div>
              <div className="productDetailpara" dangerouslySetInnerHTML={{ __html: data[i].para }}></div>
              <div className="productDetailBuyBtn">
                <OrangeButton
                  msg="buy now"
                  color="orange"
                  icon="cart"
                  to="https://redmart.lazada.sg/first-pride-123975828/?spm=a2o42.pdp_revamp.0.0.41142181cPkVQw&m=redmart&type=brand&fbclid=IwAR1hy2aEbwC_1CYC7w3s-tAUA7SaxRnjI8MUE1fthWYyeAhpII35B9Au0mc"
                  target="_blank"
                  yellow="#fff"
                />
              </div>
            </div>
          </div>
          <div className="productDetailPrepIns">
            <h2>preparation instructions</h2>
            <div className="productDetailPrep">
              <div className={`productDetailPrepSteps ${!data[i].method2 && "PrepSteps45"}`}>
                <div className="productDetailPrepIcon cIcon unpackIcon">
                  <StaticImage loading="lazy" src="../img/icons/unpack1.webp" alt="Unpack" />
                </div>
                <div className="productDetailPrepIconDesc">
                  <div className="p-regular" dangerouslySetInnerHTML={{ __html: data[i].initial }}></div>
                </div>
              </div>
              <div className={`productDetailPrepSteps ${!data[i].method2 && "PrepSteps45"}`}>
                <div className="productDetailPrepIcon cIcon">
                  <img loading="lazy" src={data[i].method1Icon} alt="Unpack" />
                  <span className="temp" dangerouslySetInnerHTML={{ __html: data[i].method1Temp }}></span>
                  <span className="time p-regular">{data[i].method1Time}</span>
                </div>
                <div className="productDetailPrepIconDesc">
                  <div className="p-regular" dangerouslySetInnerHTML={{ __html: data[i].method1 }}></div>
                </div>
              </div>
              {data[i].method2 ? (
                <div className="productDetailPrepSteps">
                  <div className="productDetailPrepIcon cIcon">
                    <img loading="lazy" src={data[i].method2Icon} alt="Unpack" />
                    <span className="temp" dangerouslySetInnerHTML={{ __html: data[i].method2Temp }}></span>
                    <span className="time p-regular">{data[i].method2Time}</span>
                  </div>
                  <div className="productDetailPrepIconDesc ">
                    <div className="p-regular" dangerouslySetInnerHTML={{ __html: data[i].method2 }}></div>
                  </div>
                </div>
              ) : (
                <div className="productDetailPrepSteps PrepSteps10">
                  <div className="productDetailPrepIconDesc p-regular" style={{ whiteSpace: "pre-wrap" }}>
                    {data[i].prepWarnings}
                  </div>
                </div>
              )}
              {data[i].method3 && (
                <div className="productDetailPrepSteps">
                  <div className="productDetailPrepIcon cIcon">
                    <img loading="lazy" src={data[i].method3Icon} alt="Unpack" />
                    <span className="temp" dangerouslySetInnerHTML={{ __html: data[i].method3Temp }}></span>
                    <span className="time p-regular">{data[i].method3Time}</span>
                  </div>
                  <div className="productDetailPrepIconDesc ">
                    <div className="p-regular" dangerouslySetInnerHTML={{ __html: data[i].method3 }}></div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="productDetailIndgredients">
            <div className="productDetailIndgredient">
              <h2>Ingredients</h2>
              <img loading="lazy" src={data[i].ing} alt="popcornbtes" />
            </div>
            <div className="productDetailIndgredientsList">
              <p dangerouslySetInnerHTML={{ __html: data[i].ingredients }}></p>
            </div>
            <div className="productDetailIndgredientSpices p-regular">
              <p className="p-spices">{data[i].spices}</p>
            </div>
          </div>
          <div className="productDetailInfoandWarning">
            <div className="productDetailInfoW">
              <div>
                <h2>Information for Food Allergies</h2>
                <p>{data[i].info}</p>
              </div>
              <div className="productDetailInfoImg pdImg">
                <img loading="lazy" src={Gluten} alt="Gluten" />
              </div>
            </div>

            <div className="productDetailInfoW">
              <div>
                <h2>Warning and Storage Instructions</h2>
                <p>Do not reheat or refreeze once defrosted. Keep frozen at -18°C or below.</p>
              </div>
              <div className="productDetailWarningImg pdImg">
                <img loading="lazy" src={Freeze} alt="Freeze" />
              </div>
            </div>
          </div>
          <div className="relatedProd">
            <h2>RELATED PRODUCTS</h2>
            <div className="ssWrapper" style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
              {data[i].relatedproducts.map((item, i) => (
                <Link key={item.path} to={"/products/" + item.path} state={{ productId: item.id }}>
                  <div className="seriesProduct" key={i}>
                    <div className="seriesProductImg">
                      <img loading="lazy" src={item.image} alt={item.alt} />
                    </div>
                    <div className="seriesProductDetails titleHeight">
                      <h3>{item.title}</h3>
                      <div className="seriesProductWP">
                        <p style={{ textTransform: "lowercase" }} className="p-regular">
                          {item.weight}
                        </p>
                        <p style={{ fontSize: "clamp(0.9rem, 1.5vw, 2rem)" }}>{item.price}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            <div className="carouselssWrapper">
              <Carousel className="carouselWrapper" interval={null}>
                {data[i].relatedproducts.map((item, i) => (
                  <Carousel.Item className="carouselItem carouselSeries" key={i}>
                    <Link to={"/products/" + item.path} state={{ productId: item.id }}>
                      <div className="carouselSeriesProduct">
                        <div className="carouselseriesProductImg">
                          <img loading="lazy" src={item.image} alt={item.alt} />
                        </div>
                        <div className="carouselseriesProductDetails" style={{ height: "150px" }}>
                          <h3>{item.title}</h3>
                          <div className="carouselseriesProductWP">
                            <p style={{ textTransform: "lowercase" }} className="p-regular">
                              {item.weight}
                            </p>
                            <p style={{ fontSize: "clamp(1rem, 5.8vw, 4rem)" }}>{item.price}</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductDetail;
